import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { pageTitle } from "../../../helper";
import Button from "../../Button";
import Cta from "../../Cta";
import Div from "../../Div";
import SectionHeading from "../../SectionHeading";
import Spacing from "../../Spacing";

export default function ProductCoinnewsafrica() {
  const params = useParams();
  pageTitle("Portfolio Details");
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Spacing lg="150" md="80" />
      <Div className="container">
        <center>
          {" "}
          <img
            src="/images/tinqfi-tinqlab.png"
            alt="Tinqfi Tinqlab"
            className="cs-radius_15 w-100"
          />
        </center>
        <Spacing lg="90" md="40" />
        <Div className="row">
          <Div className="col-lg-6">
            <SectionHeading title="TinqFi" subtitle="">
              <Spacing lg="40" md="20" />
              <p>
                The world's first fully-automated, consumer-friendly platform
                for managing digital assets. TinqFi, powered by TinqLab, will
                help its customers keep their digital assets safe and
                unlock their full value in the expanding token market.
              </p>
              <Spacing lg="10" md="10" />
              <p>
                At TinqFi, we believe that the expanding digital world will
                mostly help improve the lives of those living in the third world
                countries which brings Africa into the spotlight and we would
                like to pioneer this shift starting from Africa by providing a
                simple, seamless and secure solution to enable easy transition
                from the government controlled centralized finance system (CeFi)
                to a more decentralized means of wealth creation and asset
                management.
              </p>
            </SectionHeading>
          </Div>
          <Div className="col-lg-5 offset-lg-1">
            <Spacing lg="60" md="40" />
            <h2 className="cs-font_30 cs-font_26_sm cs-m0">Info</h2>
            <Spacing lg="50" md="30" />
            <Div className="row">
              <Div className="col-6">
                <h3 className="cs-accent_color cs-font_22 cs-font_18_sm cs-m0">
                  Category:
                </h3>
                <p className="cs-m0">Crypto Asset Manager</p>
                <Spacing lg="30" md="30" />
              </Div>

              <Div className="col-6">
                <h3 className="cs-accent_color cs-font_22 cs-font_18_sm cs-m0">
                  Launched:
                </h3>
                <p className="cs-m0">2022</p>
                <Spacing lg="30" md="30" />
              </Div>
              <Div className="col-6">
                <h3 className="cs-accent_color cs-font_22 cs-font_18_sm cs-m0">
                  Website:
                </h3>
                <a
                  href="https://tinqfi.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {" "}
                  <p className="cs-m0">Visit TinqFi</p>
                </a>
                <Spacing lg="30" md="30" />
              </Div>
            </Div>
          </Div>
        </Div>
        <Spacing lg="65" md="10" />
        <Div className="cs-page_navigation cs-center">
          <Div>
            <Button
              btnLink="/product/coinnewsafrica"
              btnText="Prev Product"
              variant="cs-type1"
            />
          </Div>
          <Div>
            <Button btnLink="/product/Tinqpay" btnText="Next Product" />
          </Div>
        </Div>
      </Div>
      <Spacing lg="145" md="80" />
    </>
  );
}

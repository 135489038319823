import React, { useEffect } from "react";
import { pageTitle } from "../../helper";
import Cta from "../Cta";
import FunFact from "../FunFact";
import PageHeading from "../PageHeading";
import Div from "../Div";
import SectionHeading from "../SectionHeading";
import TeamSlider from "../Slider/TeamSlider";
import Spacing from "../Spacing";

export default function AboutPage() {
  pageTitle("About Tinqlab");

  // FunFact Data
  const funfaceData = [
    {
      title: "Insanely Good Techies",
      factNumber: "20+",
    },
    {
      title: "Team members",
      factNumber: "25+",
    },
    {
      title: "Web3 projects completed",
      factNumber: "30+",
    },
    {
      title: "Tinqlab Ecosystem Product Launched",
      factNumber: "3+",
    },
  ];
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      {/* Start Page Heading Section */}
      {/* <PageHeading
        title="About Us"
        bgSrc="images/about_hero_bg.jpeg"
        pageLinkText="About Us"
      /> */}
      {/* End Page Heading Section */}

      {/* Start About Section */}
      <Spacing lg="150" md="80" />
      <Div className="container">
        <Div className="row">
          <Div className="col-xl-5 col-lg-7">
            <SectionHeading title="" subtitle="About Tinqlab">
              <h3>Our aim is to accelerate the current financial revolution</h3>
              <Spacing lg="30" md="20" />
              <p className="cs-m0">
                TinqLab was founded in 2019 as a SaaS startup that provides
                simple blockchain solutions and assists brands in digitizing
                various sectors. We have created a solid result-first strategy
                with the primary goal of providing users with a safe and
                frictionless experience. We are developing unique technologies
                to facilitate global DEFI adoption.
              </p>
              <Spacing lg="30" md="30" />
              <Div className="cs-separator cs-accent_bg"></Div>
              <Spacing lg="25" md="40" />
            </SectionHeading>
          </Div>
          <Div className="col-lg-5 offset-xl-2">
            <img
              src="/images/about_img_1.png"
              alt="About"
              className="w-100 cs-radius_15"
            />
            <Spacing lg="25" md="25" />
          </Div>
          <Div className="col-lg-7">
            <img
              src="/images/about_img_2.png"
              alt="About"
              className="w-100 cs-radius_15"
            />
            <Spacing lg="25" md="25" />
          </Div>
          <Div className="col-lg-5">
            <img
              src="/images/about_img_3.png"
              alt="About"
              className="w-100 cs-radius_15"
            />
            <Spacing lg="25" md="25" />
          </Div>
        </Div>
      </Div>
      <Spacing lg="75" md="55" />
      {/* End About Section */}

      {/* Start Fun Fact Section */}
      <Div className="container">
        <FunFact
          variant="cs-type1"
          title="Our fun fact"
          subtitle="we are Web3 nerds who believe in the power of DeFi to change the world"
          data={funfaceData}
        />
      </Div>
      {/* End Fun Fact Section */}

      {/* Start Why Choose Section */}
      <Spacing lg="100" md="80" />
      <Div className="container">
        <Div className="row">
          <Div className="col-xl-5 col-lg-6">
            <Div className="cs-image_layer cs-style1">
              <Div className="cs-image_layer_in">
                <img
                  src="/images/about_tinqlab.png"
                  alt="About"
                  className="w-100 cs-radius_15"
                />
              </Div>
            </Div>
            <Spacing lg="0" md="40" />
          </Div>
          <Div className="col-xl-5 offset-xl-1 col-lg-6">
            <SectionHeading title="" subtitle="Why Choose Us">
              <h3>When experience meets passion</h3>
              <Spacing lg="30" md="20" />
              <p className="cs-m0">
                TinqLab prioritizes originality, directness, and fluidity while
                also paying close attention to security; we are Tinqers and
                doers, which is how we simplify innovation. We also have a
                global team, are fully compliant, and have made blockchain
                solutions that millions of people use. However, we want to make
                DeFi easier for the more than 1 billion people who use it
                worldwide.
              </p>
              <Spacing lg="15" md="15" />
              <p className="cs-m0">
                This is the main factor that sets us apart from our competition
                and allows us to deliver a specialist business consultancy
                service. Our team applies its wide-ranging experience to
                determining.
              </p>
              <Spacing lg="30" md="30" />
              <Div className="cs-separator cs-accent_bg"></Div>
              <Spacing lg="25" md="0" />
            </SectionHeading>
          </Div>
        </Div>
      </Div>
      {/* End Why Choose Section */}

      {/* Start CTA Section */}
      <Spacing lg="150" md="80" />
      <Div className="container">
        <Cta
          title="Looking at building something cool? <br />Let's discuss"
          btnText="Apply For Meeting"
          btnLink="/contact"
          bgSrc="/images/cta_bg.jpeg"
        />
      </Div>
      {/* End CTA Section */}
    </>
  );
}

import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { pageTitle } from "../../../helper";
import Button from "../../Button";
import Cta from "../../Cta";
import PageHeading from "../../PageHeading";
import Div from "../../Div";
import SectionHeading from "../../SectionHeading";
import Spacing from "../../Spacing";

export default function ProductCoinnewsafrica() {
  const params = useParams();
  pageTitle("Portfolio Details");
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Spacing lg="150" md="80" />
      <Div className="container">
        <center>
          {" "}
          <img
            src="/images/tinqswap-tinqlab.png"
            alt="Tinqswap Tinqlab"
            className="cs-radius_15 w-100"
          />
        </center>
        <Spacing lg="90" md="40" />
        <Div className="row">
          <Div className="col-lg-6">
            <center>
              <p
                style={{
                  fontSize: "2rem",
                  fontWeight: "bold",
                  textAlign: "center",
                  color: "white",
                }}
              >
                TinqSwap is Coming Soon
              </p>{" "}
              {/* <SectionHeading title="TinqSwap is Coming Soon" subtitle=""> */}
              <Spacing lg="40" md="20" />
              <p>
                TinqSwap is a multi-chain Dex designed with deep orderbook
                liquidity and the ability to create limit trades, bridging the
                gap between DeFi mainstream acceptance. To achieve
                interoperability, trades can be pulled from any chain.
              </p>
              <Spacing lg="10" md="10" />
            </center>
          </Div>
          <Div className="col-lg-5 offset-lg-1">
            <Spacing lg="60" md="40" />
            <h2 className="cs-font_30 cs-font_26_sm cs-m0"></h2>
            <Spacing lg="50" md="30" />
            <Div className="row">
              <Div className="col-6">
                <h3 className="cs-accent_color cs-font_22 cs-font_18_sm cs-m0"></h3>
                <p className="cs-m0"></p>
                <Spacing lg="30" md="30" />
              </Div>

              <Div className="col-6">
                <h3 className="cs-accent_color cs-font_22 cs-font_18_sm cs-m0"></h3>
                <p className="cs-m0"></p>
                <Spacing lg="30" md="30" />
              </Div>
            </Div>
          </Div>
        </Div>
        <Spacing lg="65" md="10" />
        <Div className="cs-page_navigation cs-center">
          <Div>
            <Button
              btnLink="/product/tinqpay"
              btnText="Prev Project"
              variant="cs-type1"
            />
          </Div>
          <Div>
            <Button btnLink="/product/coinnewsafrica" btnText="Next Project" />
          </Div>
        </Div>
      </Div>
      <Spacing lg="145" md="80" />
    </>
  );
}

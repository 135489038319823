import React from "react";
import { Link } from "react-router-dom";
import { FaTwitter, FaLinkedinIn, FaInstagram } from "react-icons/fa";
import Div from "../Div";

export default function SocialWidget() {
  return (
    <Div className="cs-social_btns cs-style1">
      <Link to="https://linkedin.com/company/tinqlabtech" className="cs-center">
        <FaLinkedinIn />
      </Link>
      <Link to="https://twitter.com/tinqlabtech" className="cs-center">
        <FaTwitter />
      </Link>
      {/* {/* <Link to="/" className="cs-center">
        <Icon icon="fa6-brands:youtube" />
      </Link> */}
      <Link to="https://linkedin.com/company/tinqlabtech" className="cs-center">
        <FaInstagram />
      </Link>
    </Div>
  );
}

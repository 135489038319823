import React, { useEffect } from "react";
import Card from "../Card";
import FunFact from "../FunFact";
import Hero from "../Hero";
import Div from "../Div";
import SectionHeading from "../SectionHeading";
import Spacing from "../Spacing";
import Cta from "../Cta";
import LogoList from "../LogoList";
import MovingText from "../MovingText";
import PortfolioSlider from "../Slider/PortfolioSlider";
import TeamSlider from "../Slider/TeamSlider";
import { pageTitle } from "../../helper";

export default function Home() {
  pageTitle("Home");

  // Hero Social Links
  const heroSocialLinks = [
    {
      name: "LinkedIn",
      links: "https://linkedin.com/company/tinqlabtech",
    },
    {
      name: "Twitter",
      links: "https://twitter.com/tinqlabtech",
    },
    {
      name: "Instagram",
      links: "https://linkedin.com/company/tinqlabtech",
    },
  ];

  // FunFact Data
  const funfaceData = [
    {
      title: "Insanely Good Techies",
      factNumber: "20+",
    },
    {
      title: "Team members",
      factNumber: "25+",
    },
    {
      title: "Web3 projects completed",
      factNumber: "30+",
    },
    {
      title: "Tinqlab Ecosystem Product Launched",
      factNumber: "3+",
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {/* Start Hero Section */}
      <Hero
        title="Welcome To <br/>Tinqlab "
        subtitle="We are the best software development company around. We are experts in the development of  web, mobile, and blockchain solutions."
        btnText="Checkout Our Products"
        btnLink="/product/coinnewsafrica"
        scrollDownId="#products"
        socialLinksHeading="Follow Us"
        heroSocialLinks={heroSocialLinks}
        bgImageUrl="/images/hero_bg.jpeg"
      />
      {/* End Hero Section */}

      {/* Start FunFact Section */}
      <div className="container">
        <FunFact
          variant="cs-type1"
          title="Our fun fact"
          subtitle="we are Web3 nerds who believe in the power of DeFi to change the world"
          data={funfaceData}
        />
      </div>
      {/* End FunFact Section */}

      {/* Start Service Section */}
      <Spacing lg="150" md="80" />
      <Div id="service">
        <Div className="container">
          <Div className="row">
            <Div className="col-xl-4">
              <SectionHeading
                title="Our core strength"
                subtitle="We are specialist"
                btnText="Look here"
                btnLink="#"
              />
              <Spacing lg="90" md="45" />
            </Div>
            <Div className="col-xl-8">
              <Div className="row">
                <Div className="col-lg-3 col-sm-6 cs-hidden_mobile"></Div>
                <Div className="col-lg-3 col-sm-6">
                  <Card
                    title="Decentralized Finance Solutions"
                    link="#"
                    src="/images/bitcoin-tinqlab.png"
                    alt="Service"
                  />
                  <Spacing lg="0" md="30" />
                </Div>
                <Div className="col-lg-3 col-sm-6 cs-hidden_mobile"></Div>
                <Div className="col-lg-3 col-sm-6">
                  <Card
                    title="Centralized Finance Solutions"
                    link="#"
                    src="/images/network-tinqlab.png"
                    alt="Service"
                  />
                  <Spacing lg="0" md="30" />
                </Div>
                <Div className="col-lg-3 col-sm-6">
                  <Card
                    title="Mobile - Flutter"
                    link="#"
                    src="/images/chain-tinqlab.png"
                    alt="Service"
                  />
                  <Spacing lg="0" md="30" />
                </Div>
                <Div className="col-lg-3 col-sm-6 cs-hidden_mobile"></Div>
                <Div className="col-lg-3 col-sm-6">
                  <Card
                    title="Everything Web3"
                    link="#"
                    src="/images/person-tinqlab.png"
                    alt="Service"
                  />
                  <Spacing lg="0" md="30" />
                </Div>

                <Div className="col-lg-3 col-sm-6 cs-hidden_mobile"></Div>
              </Div>
            </Div>
          </Div>
        </Div>
      </Div>
      {/* End Service Section */}

      {/* Start Portfolio Section */}
      <Spacing lg="150" md="50" />
      <Div>
        <Div className="container">
          <SectionHeading
            title="Our Products"
            subtitle="Latest Projects"
            variant="cs-style1 text-center"
          />
          <Spacing lg="90" md="45" />
        </Div>
        <PortfolioSlider />
      </Div>

      {/* Start MovingText Section */}
      <Spacing lg="125" md="70" />
      <MovingText text=" -- Our partners -- " />
      <Spacing lg="105" md="70" />
      {/* End MovingText Section */}

      {/* Start LogoList Section */}
      <Div className="container">
        <LogoList />
      </Div>
      <Spacing lg="150" md="80" />
      {/* End LogoList Section */}

      {/* Start CTA Section */}
      <Div className="container">
        <Cta
          title="Looking at building something cool? <br />Let's discuss"
          btnText="Apply For Meeting"
          btnLink="/contact"
          bgSrc="/images/cta_bg.jpeg"
        />
      </Div>
      {/* End CTA Section */}
    </>
  );
}

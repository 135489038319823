import { Route, Routes } from "react-router-dom";
import { useRef } from "react";
import AboutPage from "./components/Pages/AboutPage";
import ContactPage from "./components/Pages/ContactPage";
import ErrorPage from "./components/Pages/ErrorPage";
import Home from "./components/Pages/Home";
import ProductCoinnewsafrica from "./components/Pages/Products/ProductCoinnewsafrica";
import ProductTinqFi from "./components/Pages/Products/ProductTinqFi";
import ProductTinqPay from "./components/Pages/Products/ProductTinqPay";
import ProductTinqSwap from "./components/Pages/Products/ProductTinqSwap";
import ServiceDetailsPage from "./components/Pages/ServiceDetailsPage";
import ServicesPage from "./components/Pages/ServicesPage";
import Layout from "./components/Layout";
import TawkMessengerReact from "@tawk.to/tawk-messenger-react";

function App() {
  const tawkMessengerRef = useRef();

  const handleMinimize = () => {
    tawkMessengerRef.current.minimize();
  };

  return (
    <>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />

          <Route path="about" element={<AboutPage />} />
          <Route path="service" element={<ServicesPage />} />
          <Route
            path="service/:serviceDetailsId"
            element={<ServiceDetailsPage />}
          />
          <Route
            path="product/coinnewsafrica"
            element={<ProductCoinnewsafrica />}
          />
          <Route path="product/TinqFi" element={<ProductTinqFi />} />
          <Route path="product/TinqPay" element={<ProductTinqPay />} />
          <Route path="product/tinqswap" element={<ProductTinqSwap />} />
          <Route path="contact" element={<ContactPage />} />
        </Route>
        <Route
          path="/"
          element={<Layout headerVariant="cs-site_header_full_width" />}
        ></Route>
        <Route path="*" element={<ErrorPage />} />
      </Routes>
      <TawkMessengerReact
        propertyId="62a1824c7b967b1179939a4d"
        widgetId="1g53e9vom"
        ref={tawkMessengerRef}
      />
    </>
  );
}

export default App;

import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { pageTitle } from "../../../helper";
import Button from "../../Button";
import Cta from "../../Cta";
import PageHeading from "../../PageHeading";
import Div from "../../Div";
import SectionHeading from "../../SectionHeading";
import Spacing from "../../Spacing";

export default function ProductCoinnewsafrica() {
  const params = useParams();
  pageTitle("Portfolio Details");
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Spacing lg="150" md="80" />
      <Div className="container">
        <center>
          {" "}
          <img
            src="/images/tinqpay-tinqlab.png"
            alt="Tinqpay Tinqlab"
            className="cs-radius_15 w-100"
          />
        </center>
        <Spacing lg="90" md="40" />
        <Div className="row">
          <Div className="col-lg-6">
            <SectionHeading title="TinqPay" subtitle="">
              <Spacing lg="40" md="20" />
              <p>
                TinqLab's TinqPay is a borderless, secure, and contactless
                crypto/fiat payment platform. Shop or send fiat/crypto to
                friends and family around the world with instant fiat settlement
              </p>
              <Spacing lg="10" md="10" />
            </SectionHeading>
          </Div>
          <Div className="col-lg-5 offset-lg-1">
            <Spacing lg="60" md="40" />
            <h2 className="cs-font_30 cs-font_26_sm cs-m0">Info</h2>
            <Spacing lg="50" md="30" />
            <Div className="row">
              <Div className="col-6">
                <h3 className="cs-accent_color cs-font_22 cs-font_18_sm cs-m0">
                  Category:
                </h3>
                <p className="cs-m0">Web3 Payment</p>
                <Spacing lg="30" md="30" />
              </Div>

              <Div className="col-6">
                <h3 className="cs-accent_color cs-font_22 cs-font_18_sm cs-m0">
                  Launched:
                </h3>
                <p className="cs-m0">2023</p>
                <Spacing lg="30" md="30" />
              </Div>
              <Div className="col-6">
                <h3 className="cs-accent_color cs-font_22 cs-font_18_sm cs-m0">
                  Website:
                </h3>
                <a
                  href="https://tinqpay.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {" "}
                  <p className="cs-m0">Visit TinqFi</p>
                </a>
                <Spacing lg="30" md="30" />
              </Div>
            </Div>
          </Div>
        </Div>
        <Spacing lg="65" md="10" />
        <Div className="cs-page_navigation cs-center">
          <Div>
            <Button
              btnLink="/product/tinqfi"
              btnText="Prev Product"
              variant="cs-type1"
            />
          </Div>
          <Div>
            <Button btnLink="/product/Tinqswap" btnText="Next Product" />
          </Div>
        </Div>
      </Div>
      <Spacing lg="145" md="80" />
    </>
  );
}

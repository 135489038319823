import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { pageTitle } from "../../../helper";
import Button from "../../Button";
import Div from "../../Div";
import SectionHeading from "../../SectionHeading";
import Spacing from "../../Spacing";

export default function ProductCoinnewsafrica() {
  const params = useParams();
  pageTitle("Portfolio Details");
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Spacing lg="150" md="80" />
      <Div className="container">
        <center>
          {" "}
          <img
            src="/images/coinnewsafrica-tinqlab.png"
            alt="Details"
            className="cs-radius_15 w-100"
          />
        </center>
        <Spacing lg="90" md="40" />
        <Div className="row">
          <Div className="col-lg-6">
            <SectionHeading title="Coinnewsafrica" subtitle="">
              <Spacing lg="40" md="20" />
              <p>
                Coinnewsafrica is a non-biased news source for the
                cryptocurrency, blockchain, decentralized application, IoT,
                financial, and next-generation web communities in Africa. Expert
                opinion and commentary from the cryptocurrency world is
                emphasized alongside the latest news, prices, breakthroughs, and
                analysis.
              </p>
              <Spacing lg="10" md="10" />
              <p>
                Coinnewsafrica was established in the midst of the 2017
                cryptocurrency boom and has been covering the sector ever since.
                We foresaw an expanding demand for knowledge in this sector, and
                saw an opening for our media company to become the go-to
                authority in this field. After only four years, we have become
                the go-to source for information about cryptocurrencies and the
                most active crypto-community leader on the continent.
              </p>
            </SectionHeading>
          </Div>
          <Div className="col-lg-5 offset-lg-1">
            <Spacing lg="60" md="40" />
            <h2 className="cs-font_30 cs-font_26_sm cs-m0">Info </h2>
            <Spacing lg="50" md="30" />
            <Div className="row">
              <Div className="col-6">
                <h3 className="cs-accent_color cs-font_22 cs-font_18_sm cs-m0">
                  Category:
                </h3>
                <p className="cs-m0">Media</p>
                <Spacing lg="30" md="30" />
              </Div>

              <Div className="col-6">
                <h3 className="cs-accent_color cs-font_22 cs-font_18_sm cs-m0">
                  Launched:
                </h3>
                <p className="cs-m0">2017</p>
                <Spacing lg="30" md="30" />
              </Div>
              <Div className="col-6">
                <h3 className="cs-accent_color cs-font_22 cs-font_18_sm cs-m0">
                  Website:
                </h3>
                <a
                  href="https://coinnewsafrica.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {" "}
                  <p className="cs-m0">Visit Coinnewsafrica</p>
                </a>
                <Spacing lg="30" md="30" />
              </Div>
            </Div>
          </Div>
        </Div>
        <Spacing lg="65" md="10" />
        <Div className="cs-page_navigation cs-center">
          <Div>
            <Button
              btnLink="/product/Tinqswap"
              btnText="Prev Product"
              variant="cs-type1"
            />
          </Div>
          <Div>
            <Button btnLink="/product/TinqFi" btnText="Next Product" />
          </Div>
        </Div>
      </Div>
      <Spacing lg="145" md="80" />
    </>
  );
}

import React, { useState, useEffect } from "react";
import Div from "../Div";
import MailchimpSubscribe from "react-mailchimp-subscribe";

const CustomForm = ({
  title,
  subtitle,
  placeholder,
  status,
  message,
  onValidated,
}) => {
  const [email, setEmail] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    email &&
      email.indexOf("@") > -1 &&
      onValidated({
        EMAIL: email,
      });
  };

  useEffect(() => {
    if (status === "success") clearFields();
  }, [status]);

  const clearFields = () => {
    setEmail("");
  };

  return (
    <>
      {title && <h2 className="cs-widget_title">{title}</h2>}
      <Div className="cs-newsletter cs-style1">
        <form
          action="#"
          className="cs-newsletter_form"
          onSubmit={(e) => handleSubmit(e)}
        >
          {status === "sending" && <div className="text-black">sending...</div>}
          {status === "error" && (
            <div
              className="text-red"
              dangerouslySetInnerHTML={{ __html: message }}
            />
          )}
          {status === "success" && (
            <div
              className="text-green"
              dangerouslySetInnerHTML={{ __html: message }}
            />
          )}
          <input
            type="email"
            className="cs-newsletter_input"
            placeholder="Enter your Email Address"
            onChange={(e) => setEmail(e.target.value)}
            value={email}
            isrequired
          />
          <button className="cs-newsletter_btn" type="submit" value={email}>
            <span>Send</span>
          </button>
        </form>
        <Div className="cs-newsletter_text">{subtitle}</Div>
      </Div>
    </>
  );
};

const Newsletter = (props) => {
  const postUrl = process.env.REACT_APP_SUBSCRIPTION_URL;

  return (
    <div className="mc__form-container">
      <h5>Stay in touch</h5>
      <MailchimpSubscribe
        url={postUrl}
        render={({ subscribe, status, message }) => (
          <CustomForm
            status={status}
            message={message}
            onValidated={(formData) => subscribe(formData)}
          />
        )}
      />
      <p>
        We will send out updates on intriguing advancements in Web3 and DeFi
        space on a fairly regular basis
      </p>
    </div>
  );
};

export default Newsletter;

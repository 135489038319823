import React from "react";
import Div from "../Div";
import ContactInfoWidget from "../Widget/ContactInfoWidget";
import MenuWidget from "../Widget/MenuWidget";
import Newsletter from "../Widget/Newsletter";
import SocialWidget from "../Widget/SocialWidget";
import TextWidget from "../Widget/TextWidget";
import "./footer.scss";

export default function Footer({ copyrightText, logoSrc, logoAlt, text }) {
  // const copyrightLinks = [
  //   {
  //     title: 'Terms of Use',
  //     href: '/'
  //   },
  //   {
  //     title: 'Privacy Policy',
  //     href: '/'
  //   }
  // ]

  const serviceMenu = [
    {
      title: "Web Applications",
      href: "/contact",
    },
    {
      title: "Mobile Applications",
      href: "/contact",
    },
    {
      title: "Blockchain Applications",
      href: "/contact",
    },
  ];

  return (
    <footer className="cs-fooer">
      <Div className="cs-fooer_main">
        <Div className="container">
          <Div className="row">
            <Div className="col-lg-3 col-sm-6">
              <Div className="cs-footer_item">
                <TextWidget
                  logoSrc="/images/footer_logo.png"
                  logoAlt="Logo"
                  text="Tinqlab's team is made up of Web3 believers who are experts in product design, development, and management. At TinqLab, we Tinq to develop simple solutions"
                />
                <SocialWidget />
              </Div>
            </Div>
            <Div className="col-lg-3 col-sm-6">
              <Div className="cs-footer_item">
                <MenuWidget menuItems={serviceMenu} menuHeading="Services" />
              </Div>
            </Div>
            <Div className="col-lg-3 col-sm-6">
              <Div className="cs-footer_item">
                <ContactInfoWidget title="Contact Us" />
              </Div>
            </Div>
            <Div className="col-lg-3 col-sm-6">
              <Div className="cs-footer_item">
                <Newsletter
                  title="Stay in Touch"
                  subtitle="We will send out updates on intriguing advancements in Web3 and DeFi space on a fairly regular basis."
                  placeholder="info@tinqlab.com"
                />
              </Div>
            </Div>
          </Div>
        </Div>
      </Div>
      <Div className="container">
        <Div className="cs-bottom_footer">
          <Div className="cs-bottom_footer_left">
            <Div className="cs-copyright">
              Copyright © 2024 Tinqlab Technology.
            </Div>
          </Div>
          {/* <Div className="cs-bottom_footer_right">
            <MenuWidget menuItems={copyrightLinks} variant=" cs-style2" />
          </Div> */}
        </Div>
      </Div>
    </footer>
  );
}

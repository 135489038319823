import React from "react";
import Portfolio from "../Product";
import Div from "../Div";
import Slider from "react-slick";

export default function PortfolioSlider() {
  const portfolioData = [
    {
      title: "Coinnewsafrica",
      subtitle: "See Details",
      href: "/product/coinnewsafrica",
      src: "/images/coinnewsafrica-tinqlab.png",
    },
    {
      title: "Tinq Finance",
      subtitle: "See Details",
      href: "/product/tinqfi",
      src: "/images/tinqfi-tinqlab.png",
    },

    {
      title: "TinqPay",
      subtitle: "Coming Soon",
      href: "/product/tinqpay",
      src: "/images/Tinqpay.png",
    },
    {
      title: "Tinqswap",
      subtitle: "Coming Soon",
      href: "/product/tinqswap",
      src: "/images/Tinqswap.png",
    },
  ];

  /** Slider Settings **/
  const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "0",
    slidesToShow: 3,
    speed: 500,
    dots: true,
    arrows: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <Slider {...settings} className="cs-slider cs-style3 cs-gap-24">
      {portfolioData.map((item, index) => (
        <Div key={index}>
          <Portfolio
            title={item.title}
            subtitle={item.subtitle}
            href={item.href}
            src={item.src}
          />
        </Div>
      ))}
    </Slider>
  );
}

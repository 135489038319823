import { Icon } from "@iconify/react";
import React, { useEffect, useState } from "react";
import { pageTitle } from "../../helper";
import Div from "../Div";
import PageHeading from "../PageHeading";
import SectionHeading from "../SectionHeading";
import Spacing from "../Spacing";
import ContactInfoWidget from "../Widget/ContactInfoWidget";
import axios from "axios";
import qs from "qs";
import { useForm } from "react-hook-form";
import emailjs from "@emailjs/browser";

export default function ContactPage() {
  const [sending, setSending] = useState(false);
  const [sent, setSent] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  pageTitle("Contact Us - Tinqlab");
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const sendEmail = (data) => {
    setSending(true);
    emailjs
      .send(
        process.env.REACT_APP_SERVICE,
        process.env.REACT_APP_TEMPLATE,
        data,
        process.env.REACT_APP_EMAILJS_ID
      )
      .then((result) => {
        if (result.status === 200) setSending(false);
        else setSending(true);
      });
    // var datas = {
    //   service_id: "service_tinqlab",
    //   template_id: "template_tinqlab",
    //   user_id: "nCfpRyY-REsEDxSqX",
    //   template_params: data,
    // };

    // const options = {
    //   method: "POST",
    //   data: qs.stringify(datas),
    //   url: "https://api.emailjs.com/api/v1.0/email/send",
    // };
    // try {
    //   axios(options).then((result) => console.log(result));
    // } catch (e) {
    //   console.log(e.message);
    // }
  };
  return (
    <>
      {/* <PageHeading
        title="Contact Us"
        bgSrc="/images/contact_hero_bg.jpeg"
        pageLinkText="Contact"
      /> */}
      <Spacing lg="150" md="80" />
      <Div className="container">
        <Div className="row">
          <Div className="col-lg-6">
            <SectionHeading
              title="Do you have a project <br/>in your mind?"
              subtitle="Getting Touch"
            />
            <Spacing lg="55" md="30" />
            <ContactInfoWidget withIcon />
            <Spacing lg="0" md="50" />
          </Div>
          <Div className="col-lg-6">
            <form action="#" className="row" onSubmit={handleSubmit(sendEmail)}>
              <Div className="col-sm-6">
                <label className="cs-primary_color">Full Name*</label>
                <input
                  type="text"
                  className="cs-form_field"
                  {...register("name", { required: true })}
                />
                {errors.fullname && (
                  <small className="text-primary-red text-xs">
                    Please enter your fullname
                  </small>
                )}
                <Spacing lg="20" md="20" />
              </Div>
              <Div className="col-sm-6">
                <label className="cs-primary_color">Email*</label>
                <input
                  type="email"
                  className="cs-form_field"
                  {...register("email", { required: true })}
                />
                {errors.email && (
                  <small className="text-primary-red text-xs">
                    Please enter your email
                  </small>
                )}
                <Spacing lg="20" md="20" />
              </Div>
              <Div className="col-sm-6">
                <label className="cs-primary_color">Project Type*</label>
                <input
                  type="text"
                  className="cs-form_field"
                  {...register("project", { required: true })}
                />
                {errors.project && (
                  <small className="text-primary-red text-xs">
                    Please enter your project type
                  </small>
                )}
                <Spacing lg="20" md="20" />
              </Div>
              <Div className="col-sm-6">
                <label className="cs-primary_color">Mobile*</label>
                <input
                  type="text"
                  className="cs-form_field"
                  {...register("mobile", { required: true })}
                />
                {errors.mobile && (
                  <small className="text-primary-red text-xs">
                    Please enter your mobile number
                  </small>
                )}
                <Spacing lg="20" md="20" />
              </Div>
              <Div className="col-sm-12">
                <label className="cs-primary_color">Message*</label>
                <textarea
                  cols="30"
                  rows="7"
                  className="cs-form_field"
                  {...register("message", { required: true })}
                ></textarea>
                {errors.message && (
                  <small className="text-primary-red text-xs">
                    Please enter a message
                  </small>
                )}
                <Spacing lg="25" md="25" />
              </Div>
              <Div className="col-sm-12">
                <button className="cs-btn cs-style1" disabled={sending}>
                  <span>
                    {sending === true ? "Sending ..." : "Send Message"}
                  </span>
                  <Icon icon="bi:arrow-right" />
                </button>
              </Div>
            </form>
            <span style={{ color: "green" }}>
              {sending === true ? "Message Sent" : ""}
            </span>
          </Div>
        </Div>
      </Div>
      <Spacing lg="150" md="80" />
    </>
  );
}
